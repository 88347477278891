<template>
  <div :id="id" style="position: absolute; opacity: 0">
    <img
      style="
        max-width: 100%;
        max-height: 100%;
        margin-left: auto;
        margin-right: auto;
      "
      :id="name"
      :usemap="'#' + useMap"
      :src="img"
      alt="The Boat Desarrollo Inmobiliario Playa del Carmen"
      @load="mapSetter"
      crossOrigin="Anonymous"
    />
    <map :name="useMap" id="data" v-for="(item, i) in coords" :key="i">
      <area target="" alt="" title="" href="#" :coords="item" shape="poly" />
    </map>
  </div>
</template>

<script>
import $ from "jquery";
import {} from "imagemapster";
export default {
  name: "CoordinateMapper",
  props: {
    id: {
      String: String,
      default: ""
    },

    img: {
      String: String,
      default: null
    },
    coords: {
      Array: Array,
      default: []
    }
  },
  computed: {
    name() {
      return "name" + this.id;
    },
    useMap() {
      return "map" + this.id;
    }
  },
  created() {
    this.mapSetter();
  },

  methods: {
    mapSetter() {
      $("#" + this.name).mapster({
        fillColor: "FFC300",
        fillOpacity: 0.5,
        staticState: true
      });
    }
  }
};
</script>
